import { Submittable } from '@/shared/interfaces/submittable';

export interface IModalState {
  modalDialog: IModalDialog | null
}

export interface IModalDialog {
  component: Submittable,
  id?: number | null,
  type?: string | null,
  details?: Object,
  props: IModalProps
  hideFooter?: boolean
}

export interface IModalProps {
  size?: string
  title?: string
  cancelLabel?: string
  submitLabel?: string
  onSuccess?: Function
  onCancel?: Function
  titleClass?: string
}

const state: IModalState = {
  modalDialog: null
}

const getters = {
  getModalDialog: () => state.modalDialog
}

const mutations = {
  setModalDialog: (state: IModalState, data: IModalDialog | null) => (state.modalDialog = data)
}

export default {
  state,
  getters,
  mutations
};
