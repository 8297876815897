

































import { Component, Prop } from 'vue-property-decorator'
import Editor from '@tinymce/tinymce-vue'
import AbstractField from '@/shared/components/form/fields/AbstractField.vue'

@Component({
  components: { Editor }
})
export default class RichEditorField extends AbstractField {
  @Prop({ default: () => [] }) keys!: string[]
  menuBar: string = 'file edit insert view format tools table custom'
  plugins: string = 'table autolink lists autoresize code fullscreen hr image preview quickbars searchreplace link'
  editor: any = null
  private apiKey: string = process.env.VUE_APP_EDITOR_KEY || 'j37zrde75yykud8nouxjki1atelvlepnq4dioiafipbahq1k'

  makeSetup(editor: any) {
    this.editor = editor
  }

  insertKey(key: string) {
    this.editor.insertContent(key)
  }
}
