































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex';
import { IModalDialog } from '@/store/modules/modalDialog';
import __ from '@/helpers/__'

@Component({
  computed: {
    ...mapGetters({
      getModalDialog: 'getModalDialog'
    })
  },
  methods: { __ }
})
export default class ServicesIndex extends Vue {
  private showModal: boolean = false
  private modalComponentExists: boolean = false

  $refs!: {
    modalComponent: any
  }

  @Watch('getModalDialog')
  private onValueChange(newValue: IModalDialog | null): void {
    this.showModal = !!newValue
    this.modalComponentExists = !!newValue
    this.onSuccess = null
    if (this.$store.getters.getModalDialog !== null) {
      this.onSuccess = this.$store.getters.getModalDialog.props.onSuccess
      this.onCancel = this.$store.getters.getModalDialog.props.onCancel || null
    }
  }

  close(doOnClose: boolean = false): void {
    if (doOnClose && typeof this.onCancel === 'function') {
      this.onCancel()
    }
    this.showModal = false
    window.setTimeout(() => {
      this.$store.commit('setModalDialog', null)
    }, 300)
  }

  created(): void {
    this.$root.$on('formSuccess', () => {
      if (typeof this.onSuccess === 'function') {
        this.onSuccess()
      }
      this.close(false)
    })
    this.$root.$on(['closeModal', 'bv::modal::hide'], () => {
      this.close(false)
    })
  }

  private onSuccess: Function | null = null
  private onCancel: Function | null = null

  submitDialog() {
    this.$refs.modalComponent.submitDialog()
  }

  private getModalProp(prop: string, fallback: string):string {
    if (this.modalComponentExists) {
      return this.$store.getters.getModalDialog?.props[prop] || fallback
    }
    return fallback
  }

  private breakableUnderscores(text: string):string {
    return text.replace(/[-_]/g, '$&\u200B')
  }
}
