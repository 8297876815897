export enum InvoiceStatusKeys {
  unpaid = 'unpaid',
  paid = 'paid',
  partiallyPaid = 'partially-paid',
  notApproved = 'not-approved'
}

export const InvoiceStatusKeysData: any = {
  [InvoiceStatusKeys.notApproved]: 'Nepatvirtinta',
  [InvoiceStatusKeys.unpaid]: 'Neapmokėta',
  [InvoiceStatusKeys.paid]: 'Apmokėta',
  [InvoiceStatusKeys.partiallyPaid]: 'Dalinai apmokėta'
}

export enum InvoiceTypes {
  standard = 'standard',
  generated = 'generated',
  prepaid = 'prepaid',
  draft = 'draft'
}
