
















































import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import tokenHelper from '@/helpers/token'
import __ from '@/helpers/__'
import AuthLayout from '@/views/auth/AuthLayout.vue'
import { AuthRoutes } from '@/router/routes/auth'
import { UserActions } from '@/store/modules/user/user.actions'
import { UserMutations } from '@/store/modules/user/user.mutations'
import AuthService from '@/services/AuthService'
import ChoiceToggle from '@/shared/components/ChoiceToggle.vue';

@Component({
  components: { ChoiceToggle, AuthLayout },
  methods: { __ }
})
export default class LoginUser extends Vue {
  AuthRoutes = AuthRoutes
  error: any = {}
  data: any = {
    email: this.$route.params.email || '',
    password: '',
    remember: false
  }

  submit(): void {
    AuthService.login(this.data)
      .then((response: any) => {
        tokenHelper.setToken(response.access_token)
        this.$store.dispatch(UserActions.refreshUser)
        this.$store.commit(
          UserMutations.setRefresh,
          setInterval(() => {
            this.$store.dispatch(UserActions.refreshToken)
          }, 600000)
        )
        if (typeof this.$route.params.url !== 'undefined') {
          this.$router.push({ path: this.$route.params.url })
          return
        }
        setTimeout(() => {
          location.reload()
        }, 100)
      })
      .catch((error: any) => (this.error = error))
  }
}
