import auth from '@/router/middleware/auth'
import { RouteConfig } from 'vue-router'
import __ from '@/helpers/__';

export enum GeneralRoutes {
  dashboard = 'dashboard',
  change = 'change-password',

  clientsIndex = 'clients',
  clientsCreate = 'clients/create',
  client = 'client',
  clientPaymentsEdit = 'client/payments/edit',
  clientPaymentsDelete = 'client/payments/delete',

  servicesIndex = 'services',
  servicesCreate = 'services/create',
  servicesEdit = 'services/edit',
  servicesDelete = 'services/delete',

  settingsDocuments = 'settings/documents',
  settingsGeneral = 'settings/general',
  settingsReminder = 'settings/reminder',
  settingsOffer = 'settings/offer',

  documentsVATSCreate = 'settings/documents/vats/create',
  documentsUnitsCreate = 'settings/documents/units/create',
  documentsPaymentMethodsCreate = 'settings/documents/paymentMethods/create',
  documentsDeleteType = 'settings/documents/delete',
  documentsMasksCreate = 'settings/documents/masks/create',
  documentsMasksDelete = 'settings/documents/masks/delete',

  offer = 'offer',
  offersIndex = 'offers',
  offersDelete = 'offers/delete',
  offersEdit = 'offers/edit',
  offersCreate = 'offers/create',

  offerTemplatesIndex = 'templates',
  offerTemplatesCreate = 'templates/create',
  offerTemplatesEdit = 'templates/edit',
  offerTemplatesDelete = 'templates/delete',

  invoice = 'invoice',
  invoicesIndex = 'invoices',
  invoicesDrafts = 'invoiceDrafts',
  invoicesDraftInner = 'invoiceDraftInner',
  invoicesDraftEdit = 'invoicesDraftEdit',
  invoicesCreate = 'invoices/create',
  invoicesEdit = 'invoices/edit',
  invoicesDelete = 'invoices/delete',

  prepaidInvoice = 'prepaidInvoice',
  prepaidInvoicesIndex = 'prepaidInvoices',
  prepaidInvoicesCreate = 'prepaidInvoices/create',
  prepaidInvoicesEdit = 'prepaidInvoices/edit',
  prepaidInvoicesDelete = 'prepaidInvoices/delete',

  invoiceTemplatesIndex = 'invoiceTemplates',
  invoiceTemplatesDelete = 'invoiceTemplates/delete',
  invoiceTemplatesCreate = 'invoiceTemplates/create',
  invoiceTemplatesEdit = 'invoiceTemplates/edit',

  paymentsIndex = 'payments',
  paymentsEdit = 'payments/edit',
  paymentsDelete = 'payments/delete',

  documentsCreate = 'documents/create',
  documentsIndex = 'documents',
  documentsByGroup = 'documents/by-group',
  documentsEdit = 'documents/edit',
  documentsDelete = 'documents/delete',
  documentsFileDelete = 'documents/file/delete',
  document = 'document',
  documentsTemplatesIndex = 'documentsTemplates',
  documentsTemplatesDelete = 'documentsTemplates/delete',
  documentsTemplatesCreate = 'documentsTemplates/create',
  documentsTemplatesEdit = 'documentsTemplates/edit',
  documentsTemplatesInner = 'documentsTemplates/inner',

  requestIndex = 'requests',
  requestByStatus = 'requests/by-status',
  requestCreate = 'requests/create',
  requestEdit = 'requests/edit',
  requestDelete = 'requests/delete',
  requestInner = 'request',

  paymentCreate = 'payment/create',
  prepaidPaymentCreate = 'prepaidPayment/create',

  reminderTemplatesIndex = 'reminderTemplates',
  reminderTemplatesCreate = 'reminderTemplates/create',
  reminderTemplatesEdit = 'reminderTemplates/edit',
  reminderTemplatesDelete = 'reminderTemplates/delete',

  usersIndex = 'users',
  usersCreate = 'users/create',
  usersDisable = 'users/disable',
  usersEdit = 'users/edit',
  user = 'user',

  workersIndex = 'workers',
  workersCreate = 'workers/create',
  workersEdit = 'workers/edit',
  worker = 'worker',

  procurementIndex = 'procurements',
  procurementSearch = 'procurements/search',
  procurementSettings = 'procurements/settings',
  procurementInner = 'procurement',
  procurementCompanies = 'procurementCompanies',
  procurementCompanyInner = 'procurementCompany',

  defaultTemplatesIndex = 'defaultTemplatesIndex',
  defaultTemplatesEdit = 'defaultTemplatesEdit',

  projectsIndex = 'projects',
  projectsCreate = 'projects/create',
  projectsEdit = 'projects/edit',
}

const general: RouteConfig[] = [
  {
    path: '/',
    name: GeneralRoutes.dashboard,
    component: () => import(/* webpackChunkName: "general" */ '@/views/dashboard/Dashboard.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.dashboard.title')
    }
  },
  {
    path: '/clients',
    name: GeneralRoutes.clientsIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/client/ClientsIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.clients.index.title'),
      linkEmit: 'openAddClientModal',
      linkTitle: __('views.clients.form.add')
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/client/ClientsCreate.vue'),
        name: GeneralRoutes.clientsCreate
      }
    ]
  },
  {
    path: '/requests',
    name: GeneralRoutes.requestIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/requests/RequestIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.requests.index.title'),
      link: GeneralRoutes.requestCreate,
      linkTitle: __('views.requests.form.add')
    }
  },
  {
    path: '/requests/create',
    name: GeneralRoutes.requestCreate,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/requests/RequestCreate.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.requests.form.create'),
      path: [GeneralRoutes.requestIndex]
    }
  },
  {
    path: '/request/:id',
    name: GeneralRoutes.requestInner,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/requests/RequestInner.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.requestByStatus]
    },
    children: [
      {
        path: 'edit',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/requests/RequestEdit.vue'),
        name: GeneralRoutes.requestEdit
      },
      {
        path: 'delete',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/requests/RequestDelete.vue'),
        name: GeneralRoutes.requestDelete
      }
    ]
  },
  {
    path: '/requests/by-status',
    name: GeneralRoutes.requestByStatus,
    meta: { heading: __('views.requests.index.title') },
    component: () => import(/* webpackChunkName: "general" */ '@/modules/requests/RequestByStatus.vue')
  },
  {
    path: '/clients/:id',
    name: GeneralRoutes.client,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/client/Client.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.clientsIndex]
    },
    children: [
      {
        path: 'invoice/:invoice_id/payment/:payment_id/edit',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/payment/PaymentsEdit.vue'),
        name: GeneralRoutes.clientPaymentsEdit
      },
      {
        path: 'invoice/:invoice_id/payment/:payment_id/delete',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/payment/PaymentsEdit.vue'),
        name: GeneralRoutes.clientPaymentsDelete
      }
    ]
  },
  {
    path: '/users',
    name: GeneralRoutes.usersIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/user/UsersIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.users.index.title'),
      linkEmit: 'openAddUserModal',
      linkTitle: __('views.users.form.add')
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/user/UsersCreate.vue'),
        name: GeneralRoutes.usersCreate
      }
    ]
  },
  {
    path: '/users/:id',
    name: GeneralRoutes.user,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/user/User.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.usersIndex]
    },
    children: [
      {
        path: 'disable',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/user/UsersDisable.vue'),
        name: GeneralRoutes.usersDisable
      },
      {
        path: 'edit',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/user/UsersEdit.vue'),
        name: GeneralRoutes.usersEdit
      }
    ]
  },
  {
    path: '/worker',
    name: GeneralRoutes.workersIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/worker/WorkersIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.workers.index.title'),
      linkEmit: 'openAddWorkerModal',
      linkTitle: __('views.workers.form.add')
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/worker/WorkersCreate.vue'),
        name: GeneralRoutes.workersCreate
      }
    ]
  },
  {
    path: '/workers/:id',
    name: GeneralRoutes.worker,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/worker/Worker.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.workersIndex]
    },
    children: [
      {
        path: 'edit',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/worker/WorkersEdit.vue'),
        name: GeneralRoutes.workersEdit
      }
    ]
  },
  {
    path: '/services',
    name: GeneralRoutes.servicesIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/service/ServicesIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.services.index.title'),
      linkEmit: 'openAddServiceModal',
      linkTitle: __('views.services.form.add')
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/service/ServicesCreate.vue'),
        name: GeneralRoutes.servicesCreate
      },
      {
        path: ':id/delete',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/service/ServicesDelete.vue'),
        name: GeneralRoutes.servicesDelete
      },
      {
        path: ':id/edit',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/service/ServicesEdit.vue'),
        name: GeneralRoutes.servicesEdit
      }
    ]
  },
  {
    path: '/payments',
    name: GeneralRoutes.paymentsIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/payment/PaymentsIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.payments.index.title')
    },
    children: [
      {
        path: ':payment_id/invoice/:invoice_id/edit',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/payment/PaymentsEdit.vue'),
        name: GeneralRoutes.paymentsEdit
      },
      {
        path: ':payment_id/invoice/:invoice_id/delete',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/payment/PaymentsDelete.vue'),
        name: GeneralRoutes.paymentsDelete
      }
    ]
  },
  {
    path: '/invoices',
    name: GeneralRoutes.invoicesIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/invoices/InvoicesIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.invoices.index.title'),
      link: GeneralRoutes.invoicesCreate,
      linkTitle: __('views.invoices.invoice.add')
    }
  },
  {
    path: '/invoice-drafts',
    name: GeneralRoutes.invoicesDrafts,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/invoices/InvoicesIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.invoices.index.drafts')
    }
  },
  {
    path: '/invoice-drafts/:id',
    name: GeneralRoutes.invoicesDraftInner,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/invoices/Invoice.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.invoicesDrafts]
    }
  },
  {
    path: '/invoice-drafts/:id/edit',
    name: GeneralRoutes.invoicesDraftEdit,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/invoices/InvoicesEdit.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.invoicesDrafts]
    }
  },
  {
    path: '/invoice/:id',
    name: GeneralRoutes.invoice,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/invoices/Invoice.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.invoicesIndex]
    },
    children: [
      {
        path: 'delete',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/invoices/InvoicesDelete.vue'),
        name: GeneralRoutes.invoicesDelete
      },
      {
        path: 'payment',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/payment/PaymentsCreate.vue'),
        name: GeneralRoutes.paymentCreate
      }
    ]
  },
  {
    path: '/invoices/create',
    name: GeneralRoutes.invoicesCreate,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/invoices/InvoicesCreate.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.invoices.create.title'),
      path: [GeneralRoutes.invoicesIndex]
    }
  },
  {
    path: '/invoices/:id/edit',
    name: GeneralRoutes.invoicesEdit,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/invoices/InvoicesEdit.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.invoicesIndex]
    }
  },
  {
    path: '/prepaid-invoice/:id',
    name: GeneralRoutes.prepaidInvoice,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/prepaid-invoices/PrepaidInvoice.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.prepaidInvoicesIndex]
    },
    children: [
      {
        path: 'delete',
        component: () =>
          import(/* webpackChunkName: "general" */ '@/modules/prepaid-invoices/PrepaidInvoicesDelete.vue'),
        name: GeneralRoutes.prepaidInvoicesDelete
      },
      {
        path: 'payment',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/payment/PaymentsCreate.vue'),
        name: GeneralRoutes.prepaidPaymentCreate
      }
    ]
  },
  {
    path: '/prepaid-invoices',
    name: GeneralRoutes.prepaidInvoicesIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/prepaid-invoices/PrepaidInvoicesIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.prepaid-invoices.index.title'),
      link: GeneralRoutes.prepaidInvoicesCreate,
      linkTitle: __('views.prepaid-invoices.form.add')
    }
  },
  {
    path: '/prepaid-invoices/create',
    name: GeneralRoutes.prepaidInvoicesCreate,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/prepaid-invoices/PrepaidInvoicesCreate.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.invoices.create.title'),
      path: [GeneralRoutes.prepaidInvoicesIndex]
    }
  },
  {
    path: '/prepaid-invoices/:id/edit',
    name: GeneralRoutes.prepaidInvoicesEdit,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/prepaid-invoices/PrepaidInvoicesEdit.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.prepaidInvoicesIndex]
    }
  },
  {
    path: '/invoice-templates',
    name: GeneralRoutes.invoiceTemplatesIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/invoice-templates/InvoiceTemplatesIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.invoice-templates.index.title'),
      link: GeneralRoutes.invoiceTemplatesCreate,
      linkTitle: __('views.invoice-templates.add')
    },
    children: [
      {
        path: ':id/delete',
        component: () =>
          import(/* webpackChunkName: "general" */ '@/modules/invoice-templates/InvoiceTemplatesDelete.vue'),
        name: GeneralRoutes.invoiceTemplatesDelete
      }
    ]
  },
  {
    path: '/invoice-templates/create',
    name: GeneralRoutes.invoiceTemplatesCreate,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/invoice-templates/InvoiceTemplatesCreate.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.invoice-templates.create.title'),
      path: [GeneralRoutes.invoiceTemplatesIndex]
    }
  },
  {
    path: '/invoice-templates/:id/edit',
    name: GeneralRoutes.invoiceTemplatesEdit,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/invoice-templates/InvoiceTemplatesEdit.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.invoice-templates.edit'),
      path: [GeneralRoutes.invoiceTemplatesIndex]
    }
  },
  {
    path: '/offer/:id',
    name: GeneralRoutes.offer,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/offer/Offer.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.offersIndex]
    },
    children: [
      {
        path: 'delete',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/offer/OffersDelete.vue'),
        name: GeneralRoutes.offersDelete
      }
    ]
  },
  {
    path: '/offers',
    name: GeneralRoutes.offersIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/offer/OffersIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.offers.index.title'),
      link: GeneralRoutes.offersCreate,
      linkTitle: __('views.offers.form.create')
    }
  },
  {
    path: '/offers/create',
    name: GeneralRoutes.offersCreate,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/offer/OffersCreate.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.offers.create.title'),
      path: [GeneralRoutes.offersIndex]
    }
  },
  {
    path: '/offers/:id/edit',
    name: GeneralRoutes.offersEdit,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/offer/OffersEdit.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.offersIndex]
    }
  },
  {
    path: '/documents/create',
    name: GeneralRoutes.documentsCreate,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/documents/DocumentCreate.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.documents.create.title'),
      path: [GeneralRoutes.documentsIndex]
    }
  },
  {
    path: '/documents/:id/edit',
    name: GeneralRoutes.documentsEdit,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/documents/DocumentEdit.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.documentsIndex]
    }
  },
  {
    path: '/documents/:id',
    name: GeneralRoutes.document,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/documents/Document.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.documentsIndex]
    },
    children: [
      {
        path: 'delete/:fileId',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/documents/DocumentFileDelete.vue'),
        name: GeneralRoutes.documentsFileDelete
      }
    ]
  },
  {
    path: '/documents',
    name: GeneralRoutes.documentsIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/documents/DocumentIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.documents.index.title'),
      link: GeneralRoutes.documentsCreate,
      linkTitle: __('views.documents.form.add')
    },
    children: [
      {
        path: ':id/delete',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/documents/DocumentDelete.vue'),
        name: GeneralRoutes.documentsDelete
      }
    ]
  },
  {
    path: '/documents/by-group/:id',
    component: () => import(/* webpackChunkName: "general" */ '@/modules/documents/DocumentIndex.vue'),
    name: GeneralRoutes.documentsByGroup,
    meta: {
      middleware: [auth],
      heading: __('views.documents.index.title'),
      link: GeneralRoutes.documentsCreate,
      linkTitle: __('views.documents.form.add')
    }
  },
  {
    path: '/document-templates/create',
    name: GeneralRoutes.documentsTemplatesCreate,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/documents/DocumentTemplatesCreate.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.documents.templates.create.title'),
      path: [GeneralRoutes.documentsTemplatesIndex]
    }
  },
  {
    path: '/document-templates/:id',
    name: GeneralRoutes.documentsTemplatesInner,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/documents/DocumentTemplatesInner.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.documentsTemplatesIndex]
    }
  },
  {
    path: '/document-templates/:id/edit',
    name: GeneralRoutes.documentsTemplatesEdit,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/documents/DocumentTemplatesEdit.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.documentsTemplatesIndex]
    }
  },
  {
    path: '/document-templates',
    name: GeneralRoutes.documentsTemplatesIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/documents/DocumentTemplatesIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.documents.templates.index.title'),
      link: GeneralRoutes.documentsTemplatesCreate,
      linkTitle: __('views.documents.templates.form.add')
    },
    children: [
      {
        path: 'delete',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/documents/DocumentTemplatesDelete.vue'),
        name: GeneralRoutes.documentsTemplatesDelete
      }
    ]
  },
  {
    path: '/templates',
    name: GeneralRoutes.offerTemplatesIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/offer-template/TemplatesIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.templates.index.title'),
      link: GeneralRoutes.offerTemplatesCreate,
      linkTitle: __('views.templates.form.add')
    },
    children: [
      {
        path: ':id/delete',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/offer-template/TemplatesDelete.vue'),
        name: GeneralRoutes.offerTemplatesDelete
      }
    ]
  },
  {
    path: '/templates/:id/edit',
    name: GeneralRoutes.offerTemplatesEdit,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/offer-template/TemplatesEdit.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.templates.create.edit'),
      path: [GeneralRoutes.offerTemplatesIndex]
    }
  },
  {
    path: '/templates/create',
    name: GeneralRoutes.offerTemplatesCreate,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/offer-template/TemplatesCreate.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.templates.create.title'),
      path: [GeneralRoutes.offerTemplatesIndex]
    }
  },
  {
    path: '/settings/documents',
    name: GeneralRoutes.settingsDocuments,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/settings/DocumentSettings.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.settings.index.title.document')
    },
    children: [
      {
        path: 'create/vat',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/settings/settings-create/VATCreate.vue'),
        name: GeneralRoutes.documentsVATSCreate
      },
      {
        path: 'create/unit',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/settings/settings-create/UnitCreate.vue'),
        name: GeneralRoutes.documentsUnitsCreate
      },
      {
        path: 'create/payment-method',
        component: () =>
          import(/* webpackChunkName: "general" */ '@/modules/settings/settings-create/PaymentMethodsCreate.vue'),
        name: GeneralRoutes.documentsPaymentMethodsCreate
      },
      {
        path: 'delete/:type/:id',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/settings/DeleteComponent.vue'),
        name: GeneralRoutes.documentsDeleteType
      },
      {
        path: 'mask/create',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/settings/settings-create/MaskCreate.vue'),
        name: GeneralRoutes.documentsMasksCreate
      },
      {
        path: 'mask/delete',
        component: () => import(/* webpackChunkName: "general" */ '@/modules/settings/MaskDelete.vue'),
        name: GeneralRoutes.documentsMasksDelete
      }
    ]
  },
  {
    path: '/settings/offer',
    name: GeneralRoutes.settingsOffer,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/settings/OfferSettings.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.settings.index.title.offer')
    }
  },
  {
    path: '/settings/general',
    name: GeneralRoutes.settingsGeneral,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/settings/GeneralSettings.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.settings.index.title.general')
    }
  },
  {
    path: '/settings/reminder',
    name: GeneralRoutes.settingsReminder,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/settings/ReminderSettings.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.settings.index.title.reminder')
    }
  },
  {
    path: '/settings/reminder-templates',
    name: GeneralRoutes.reminderTemplatesIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/reminder-template/ReminderTemplatesIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.reminder-templates.index.title')
    },
    children: [
      {
        path: ':id/delete',
        component: () =>
          import(/* webpackChunkName: "general" */ '@/modules/reminder-template/ReminderTemplatesDelete.vue'),
        name: GeneralRoutes.reminderTemplatesDelete
      }
    ]
  },
  {
    path: '/settings/reminder-templates/create',
    name: GeneralRoutes.reminderTemplatesCreate,
    component: () =>
      import(/* webpackChunkName: "general" */ '@/modules/reminder-template/ReminderTemplatesCreate.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.reminder-templates.create.title')
    }
  },
  {
    path: '/settings/reminder-templates/:id/edit',
    name: GeneralRoutes.reminderTemplatesEdit,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/reminder-template/ReminderTemplatesEdit.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.reminder-templates.create.title')
    }
  },
  {
    path: '/change-password',
    name: GeneralRoutes.change,
    component: () => import(/* webpackChunkName: "general" */ '@/views/auth/ChangePassword.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.auth.change-password.header')
    }
  },
  {
    path: '/procurements',
    name: GeneralRoutes.procurementIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/procurement/ProcurementIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.procurement.index.title')
    }
  },
  {
    path: '/procurement/companies',
    name: GeneralRoutes.procurementCompanies,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/procurement/ProcurementCompaniesList.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.procurement.index.companies')
    }
  },
  {
    path: '/procurement/company/:id',
    name: GeneralRoutes.procurementCompanyInner,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/procurement/ProcurementCompanyInner.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.procurementCompanies],
      heading: __('views.procurement.index.companies')
    }
  },
  {
    path: '/procurement/search',
    name: GeneralRoutes.procurementSearch,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/procurement/ProcurementSearch.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.procurement.index.search'),
      path: [GeneralRoutes.procurementIndex]
    }
  },
  {
    path: '/procurement/settings',
    name: GeneralRoutes.procurementSettings,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/procurement/ProcurementSettings.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.procurement.index.settings'),
      path: [GeneralRoutes.procurementIndex]
    }
  },
  {
    path: '/procurements/:id',
    name: GeneralRoutes.procurementInner,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/procurement/ProcurementInner.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.procurementIndex]
    }
  },
  {
    path: '/settings/default-templates',
    name: GeneralRoutes.defaultTemplatesIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/default-template/DefaultTemplatesIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.default-templates.index.title')
    }
  },
  {
    path: '/settings/default-templates/:id/edit',
    name: GeneralRoutes.defaultTemplatesEdit,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/default-template/DefaultTemplatesEdit.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.default-templates.index.edit')
    }
  },
  {
    path: '/projects',
    name: GeneralRoutes.projectsIndex,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/project/ProjectsIndex.vue'),
    meta: {
      middleware: [auth],
      heading: __('views.projects.index.title'),
      link: GeneralRoutes.projectsCreate,
      linkTitle: __('views.projects.form.create')
    }
  },
  {
    path: '/projects/create',
    name: GeneralRoutes.projectsCreate,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/project/ProjectsCreate.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.projectsIndex],
      heading: __('views.projects.create.title')
    }
  },
  {
    path: '/projects/:id',
    name: GeneralRoutes.projectsEdit,
    component: () => import(/* webpackChunkName: "general" */ '@/modules/project/ProjectsEdit.vue'),
    meta: {
      middleware: [auth],
      path: [GeneralRoutes.projectsIndex],
      heading: __('views.projects.edit.title')
    }
  }
]

export default general
