






































import { Component, Prop, Watch } from 'vue-property-decorator'
import AbstractField from '@/shared/components/form/fields/AbstractField.vue'

@Component
export default class TextField extends AbstractField {
  @Prop({ default: false }) private readonly showClear!: boolean
  @Prop({ default: 'text' }) private readonly type!: string
  @Prop({ default: undefined }) private readonly minNumber!: number | undefined
  passwordToggled: boolean = false

  togglePassword(): void {
    this.passwordToggled = !this.passwordToggled
  }

  onClearClick(): void {
    this.model = ''
    this.emitChange(this.model)
  }

  get fieldType(): string {
    if (this.type === 'password') return this.passwordToggled ? 'text' : this.type

    return this.type
  }

  @Watch('model') private onModelChange(value: any): void {
    if (this.type === 'number' && typeof value === 'string' && value.includes(',')) {
      this.value = value.replace(/,/g, '.')
    }
  }
}
