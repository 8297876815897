









































import { Component, Prop, Watch } from 'vue-property-decorator'
import AbstractField from '@/shared/components/form/fields/AbstractField.vue'
import MultiSelect from 'vue-multiselect'
import _ from 'lodash'
import http from '@/shared/helpers/http'
import { IFilter, ISearchUpdateData } from '@/shared/interfaces/form/form.interface'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'

interface ILoadItemsProps {
  endpoint: string
  filters: any
  title: string
  value: string
  plainFilter?: string
}

@Component({
  components: { MultiSelect }
})
export default class SearchableField extends AbstractField {
  @Prop() endpoint!: string
  @Prop() modelClass!: any
  @Prop() labelField!: string
  @Prop() valueField!: string
  @Prop() loadItemsProps!: ILoadItemsProps
  @Prop() clearOnOpen!: boolean
  @Prop({ default: false }) eagerLoading!: boolean
  @Prop({ default: false }) allowToCreate!: boolean

  items: Object[] = []
  fullItemData: Object[] = []
  selected: any = null
  loaded: boolean = true
  search: string = ''
  page: number = 1
  defaultPerPage: number = 999
  lastPage: number = 1
  meta: any = null
  initialLoad: boolean = false
  setCustomValue: boolean = false

  @Watch('loadItemsProps.plainFilter', { deep: true }) onPlainFilterChange(newVal: any) {
    if (!newVal) return
    this.items = []
    this.loadData('', true)
  }

  private debouncedSearch = _.debounce(this.loadData, 400)

  @Watch('model') private onModelChange(): void {
    if (!this.selected && this.model) this.loadUuidData()
  }

  mounted(): void {
    if (this.eagerLoading) {
      this.loadData()
      return
    }
    if (!this.selected && this.model) this.loadUuidData()
  }

  created(): void {
    this.$root.$on('updateSearchField', (data: ISearchUpdateData) => {
      if (this.id === data.key) {
        this.changeSearch(data.label)
        this.loadData(data.label)
      }
    })
  }

  get allItems() {
    const localItem = []
    const existingItem = this.items.find((item:any) => item.value === this.model)
    if (!existingItem && this.allowToCreate && !this.search && this.model) {
      this.search = this.model
      this.setCustomValue = true
    }
    if (this.allowToCreate && this.search) {
      localItem.push({
        label: this.search,
        value: this.search
      })
    }
    return [...localItem, ...this.items]
  }

  @Watch('allItems') onAllItemsChange() {
    if (!this.setCustomValue) {
      return
    }
    this.setCustomValue = false
    this.selected = this.allItems[0]
  }

  changeSearch(search: string): void {
    this.initialLoad = false
    this.page = 1
    this.loaded = false
    this.debouncedSearch(search)
  }

  changeValue(value: any): void {
    if (value === null) {
      this.$emit('searchableValue', null)
      this.emitChange(null)
      return
    }
    this.emitChange(value.value)

    this.$emit('searchableValue', value.fullItem)
  }

  loadData(query: any = '', forceLoad: boolean = false): void {
    this.loaded = false
    this.search = query

    if ((!this.loadItemsProps || this.initialLoad) && !forceLoad) {
      this.loaded = true
      return
    }

    http
      .get(`${this.loadItemsProps.endpoint}?q=${query}&per_page=${this.defaultPerPage}${this.filters}${this.loadItemsProps?.plainFilter || ''}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.lastPage = item?.meta?.last_page || 1
        // @ts-ignore
        this.meta = item.meta
        this.items = item.data.map((item: any) => ({
          label: this.getLabel(item, this.loadItemsProps.title),
          value: item[this.loadItemsProps.value],
          fullItem: item
        }))
        this.fullItemData = item.data
        if (this.eagerLoading) {
          const selectedItem: any = _.find(this.fullItemData, (item: any) => {
            return item[this.loadItemsProps.value] === this.value
          })
          if (typeof selectedItem !== 'undefined') {
            this.selected = {
              label: selectedItem[this.loadItemsProps.title],
              value: selectedItem[this.loadItemsProps.value]
            }
          }
        }
      })
      .finally((): void => {
        this.loaded = true
        this.initialLoad = true
      })
  }

  loadMoreData(): void {
    if (!this.loaded) return
    this.loaded = false
    http
      .get(
        `${this.loadItemsProps.endpoint}?q=${this.search}&page=${++this.page}&per_page=${this.defaultPerPage}${
          this.filters
        }`
      )
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        // @ts-ignore
        this.lastPage = item.meta.last_page
        // @ts-ignore
        this.meta = item.meta
        const newItems = item.data.map((item: any) => ({
          label: this.getLabel(item, this.loadItemsProps.title),
          value: item[this.loadItemsProps.value],
          fullItem: item
        }))
        this.items = this.items.concat(newItems)
        this.fullItemData = item.data
      })
      .finally((): void => {
        this.loaded = true
      })
  }

  loadUuidData(): void {
    if (!this.model && this.model !== 0) return
    this.loaded = false
    http
      .get(`${this.loadItemsProps.endpoint}/${this.model}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        const gotItem: any = {
          // @ts-ignore
          label: this.getLabel(item.data, this.loadItemsProps.title),
          // @ts-ignore
          value: item.data[this.loadItemsProps.value]
        }
        this.items = [gotItem]
        this.selected = gotItem
      })
      .finally((): void => {
        this.loaded = true
      })
  }

  get filters() {
    if (!this.loadItemsProps.filters) return ''

    return this.loadItemsProps.filters.map(
      (filter: IFilter) => `&filter[${filter.type}.${filter.name}]=${filter.value}`
    )
  }

  openSelect(): void {
    if (!this.initialLoad) this.debouncedSearch('')
  }

  getLabel(data: any, titles: string | string[]): string {
    let label: string = ''

    if (_.isArray(titles)) {
      _.each(titles, (title: string) => {
        if (!_.get(data, title)) return

        if (label !== '' && _.get(data, title)) {
          label += ` - ${_.get(data, title)}`
          return
        }

        label = _.get(data, title)
      })
    } else {
      label = _.get(data, titles)
    }

    return label
  }
}
